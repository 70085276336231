<template>
  <q-layout class="col row">
    <q-page-container class="column col row bg-secondary">
      <q-page class="column col row">
        <mobile-menu v-if="isMobile()"></mobile-menu>
        <menu-bar v-else
                  :is-menu-visible="true"
                  @currentUser="val => setCurrentUser(val)">
        </menu-bar>
        <div ref="thisDivHeight" class="col row q-px-xs justify-center">
          <div class="column">
            <q-card bordered style="margin-top: 130px" class="q-pa-lg q-ma-md">
              <div class="row justify-center">
                <div class="q-pl-lg" v-if="showConfetti"
                     v-confetti="{ stageHeight: divHeight, delay: 500,  colors: ['#402f6a', '#6930d5','#BEA9EE','#45e0e8'],particleCount: 200 }"/>

              </div>
              <q-card-section class="q-pa-lg">
                <div class="text-center">
                  <p class="text-h5 text-black q-mt-md" style="font-size: 30px !important;">Dziękujemy za zaufanie.
                  </p>
                  <p class="text-h5 text-black q-mt-md" style="font-size: 23px !important;">Oferty firmy <span
                      class="text-bold accent-3">{{ companyName }}</span> zostaną wkrótce dodane.</p>
                  <p class="text-h5 text-black q-mt-lg q-pt-lg q-mb-sm" style="font-size: 15px !important;">Po ich
                    opublikowaniu prześlemy Państwu link z potwierdzeniem na adres e-mail&nbsp;<span
                        class="accent-3"> {{ companyEmail }}</span>.</p>
                  <p class="text-h7 text-dark q-mb-sm" style="font-size: 15px!important;">Zgodę można w kążdej chwili
                    anulować za pomocą przycisku "Anuluj" w mailu potwierdzającym.</p>
                </div>
                <div class="text-center q-mt-lg q-pt-lg">
                  <q-btn outline class="bg-accent text-dark q-px-lg" dense no-caps size="lg" @click="goToMainBoard()">
                    Rozumiem
                  </q-btn>
                </div>
              </q-card-section>
            </q-card>
          </div>
        </div>
      </q-page>
    </q-page-container>
  </q-layout>
</template>

<script setup>
import MenuBar from "/src/components/common/MenuBar.vue";
import {onMounted, ref} from "vue";
import {useQuasar} from "quasar";
import MobileMenu from "@/components/mobile/MobileMenu";
import {currentUser} from "@/global-variables/currentUser";
import {vConfetti} from "@neoconfetti/vue";
import {useRouter, useRoute} from "vue-router";
import {
  initializeFromQuery,
  sendAgreementConsentInformation,
} from "@/services/organization/offer/consents/OfferConsentsService";
import NotificationService from "@/services/notification/NotificationService";

const $q = useQuasar();
const router = useRouter();
const route = useRoute();

const thisDivHeight = ref(null);
const divHeight = ref(0);
const companyName = ref(null);
const companyEmail = ref(null);
const token = ref(null);
const showConfetti = ref(false);

onMounted(() => {
  const queryData = initializeFromQuery(route);
  companyName.value = queryData.companyName;
  companyEmail.value = queryData.companyEmail;
  token.value = queryData.token;
  calculateDivHeightForConfetti();
  sendWebhookToSendEmail();
});

async function goToMainBoard() {
  await router.push({name: "JobOffersMainBoard"});
}

async function sendWebhookToSendEmail() {
  try {
    await sendAgreementConsentInformation(companyEmail.value, token.value, companyName.value)
        .then(isSent => {
          if (isSent) {
            showConfetti.value = true;
          } else {
            NotificationService.showNotificationWithPosition($q, 'info', "Zgoda nie może być aktywowana, skontaktuj się z nami aby otrzymać nowy link aktywacyjny", 'bottom')
          }
        });
  } catch (error) {
    console.error("Error sending webhook:", error);
  }
}

function calculateDivHeightForConfetti() {
  if (thisDivHeight.value) {
    divHeight.value = thisDivHeight.value.offsetHeight;
  }
}


function setCurrentUser(val) {
  currentUser.value = val;
}

function isMobile() {
  return $q.platform.is.mobile;
}
</script>


<style lang="scss" scoped>

.slide-wrap {
  width: 100vw;
  overflow-x: auto;
  overflow-y: hidden;
}

.slide {
  min-width: max-content;
}

</style>
