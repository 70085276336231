class ZapierHookApi {
    constructor(baseURL) {
        this.baseURL = baseURL;
    }

    async _sendGetRequest(endpoint) {
        const url = `${this.baseURL}${endpoint}`;
        const response = await fetch(url);
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response;
    }

    async sendConsentsInformation(email, securityToken, zgoda, company ) {
        const endpoint = `/hooks/catch/20992844/2s8wjj9?email=${encodeURIComponent(email)}&securityToken=${encodeURIComponent(securityToken)}&zgoda=${encodeURIComponent(zgoda)}&company=${encodeURIComponent(company)}`;
        try {
            return await this._sendGetRequest(endpoint);
        } catch (error) {
            console.error('Error sending consents information:', error);
            throw error;
        }
    }
}

export default ZapierHookApi;
export const zapierHookApiInstance = new ZapierHookApi('https://hooks.zapier.com');
