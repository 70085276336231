import {createRouter, createWebHistory} from 'vue-router'
import AddJobOffer from '../views/AddJobOffer.vue'
import JobOffersMainBoard from "@/views/JobOffersMainBoard";
import OrganizationJobOffersBoard from "@/components/organization/job-offers/OrganizationJobOffersBoard.vue"
import OrganizationJobOfferDetailsBoard
    from "@/components/organization/job-offers/details/OrganizationJobOfferDetailsBoard.vue"
import OrganizationJobOfferDetailsEditPage
    from "@/components/organization/job-offers/details/edit/OrganizationJobOfferDetailsEditPage.vue"
import ApplicantLoginPage from "@/components/common/login/ApplicantLoginPage"
import OrganizationLoginPage from "@/components/common/login/OrganizationLoginPage"
import OrganizationApplicationsBoard from "@/components/organization/applications/OrganizationApplicationsBoard.vue";
import EmployeeRegistration from "@/components/applicant/registration/EmployeeRegistration";
import OrganizationRegistration from "@/components/organization/registration/OrganizationRegistration";
import OrganizationRegistrationConfirmation
    from "@/components/organization/registration/OrganizationRegistrationConfirmation";
import OrganizationAccountManagement
    from "@/components/organization/organization-accounts/OrganizationAccountManagement";
import EmailsBoard from "@/components/organization/emails/EmailsBoard.vue";
import OrganizationDataPage from "@/components/organization/data/OrganizationDataPage";
import ApplicantRegistrationConfirmation
    from "@/components/applicant/registration/ApplicantRegistrationConfirmation.vue"
import OrganizationPasswordReset from "@/components/organization/organization-accounts/OrganizationPasswordReset"
import AddJobOfferAsLoggedUser from "@/components/organization/job-offers/adding/AddJobOfferAsLoggedUser"
import ApplicantPasswordReset from "@/components/applicant/profile/ApplicantPasswordReset"
import PlannedJobOfferEdit from "@/components/organization/job-offers/planned/PlannedJobOfferEdit.vue"
import ApplicantProfilePage from "@/components/applicant/profile/ApplicantProfilePage.vue"
import ApplicantApplications from "@/components/applicant/applications/ApplicantApplications.vue"
import ObservedApplicationsPage from "@/components/applicant/observed/ObservedApplicationsPage.vue"
import OrganizationAccountPage
    from "@/components/organization/organization-accounts/profile/OrganizationAccountPage.vue"
import InvoicesMainBoard from "@/components/organization/invoices/InvoicesMainBoard.vue"
import OrganizationAccessDenied from "@/components/organization/OrganizationAccessDenied.vue"
import EmailTemplatesPage from "@/components/organization/emails/EmailTemplatesPage.vue"
import {currentUser} from '@/global-variables/currentUser.js';
import AuthenticationService from "@/services/AuthenticationService";
import EmailAcceptedForAddingExternalOffersPage
    from "@/components/organization/job-offers/consents/EmailAcceptedForAddingExternalOffersPage.vue";
import EmailDeclineForAddingExternalOffersPage
    from "@/components/organization/job-offers/consents/EmailDeclineForAddingExternalOffersPage.vue";
const routes = [
    {
        path: '/organization/offers/add-first',
        name: 'AddJobOffer',
        component: AddJobOffer
    },
    {
        path: '/applicant/login',
        name: 'ApplicantLoginPage',
        component: ApplicantLoginPage
    },
    {
        path: '/organization/registration',
        name: 'OrganizationRegistration',
        component: OrganizationRegistration
    },
    {
        path: '/organization/login',
        name: 'OrganizationLoginPage',
        component: OrganizationLoginPage
    },
    {
        path: '/applicant/registration',
        name: 'EmployeeRegistration',
        component: EmployeeRegistration
    },
    {
        path: '/offers/:id?',
        name: 'JobOffersMainBoard',
        params: 'id',
        component: JobOffersMainBoard
    },
    {
        path: '/',
        name: 'JobOfferMainBoard',
        component: JobOffersMainBoard
    },
    {
        path: '/organization/offers',
        name: 'OrganizationJobOffersBoard',
        component: OrganizationJobOffersBoard,
        meta: {
            requiresOrganizationAuth: true,
            requiredAccess: {name: "OFFERS", actions: ["READ"]}
        }
    },
    {
        path: '/organization/offers/:id',
        name: 'OrganizationJobOfferDetailsBoard',
        component: OrganizationJobOfferDetailsBoard,
        meta: {
            requiresOrganizationAuth: true,
            requiredAccess: {name: "OFFERS", actions: ["UPDATE"]}
        }
    }
    ,
    {
        path: '/organization/offers/edit/:id',
        params: 'id',
        name: 'OrganizationJobOfferDetailsEditPage',
        component: OrganizationJobOfferDetailsEditPage,
        meta: {
            requiresOrganizationAuth: true,
            requiredAccess: {name: "OFFERS", actions: ["UPDATE"]}
        }
    },
    {
        path: '/organization/offers/:id/applications',
        params: ['id'],
        name: 'OrganizationApplicationsBoard',
        component: OrganizationApplicationsBoard,
        meta: {
            requiresOrganizationAuth: true,
        }
    },
    {
        path: '/organization/accounts',
        name: 'OrganizationAccountManagement',
        component: OrganizationAccountManagement,
        meta: {
            requiresOrganizationAuth: true,
            requiredAccess: {name: "USERS_PERMISSIONS", actions: ["UPDATE"]}
        }
    },

    {
        path: '/emails/templates',
        name: 'EmailTemplatesPage',
        component: EmailTemplatesPage,
        meta: {
            requiresOrganizationAuth: true,
        }
    },
    {
        path: '/organization/invoices',
        name: 'InvoicesMainBoard',
        component: InvoicesMainBoard,
        meta: {
            requiresOrganizationAuth: true
        }
    },
    {
        path: '/organization/registration/confirmation',
        name: 'OrganizationRegistrationConfirmation',
        component: OrganizationRegistrationConfirmation
    },
    {
        path: '/applicant/registration/confirmation',
        name: 'ApplicantRegistrationConfirmation',
        component: ApplicantRegistrationConfirmation
    },
    {
        path: '/organization/emails',
        name: 'EmailsBoard',
        component: EmailsBoard,
        meta: {
            requiresOrganizationAuth: true
        }
    },
    {
        path: '/organization/data',
        name: 'OrganizationDataPage',
        component: OrganizationDataPage,
        meta: {
            requiresOrganizationAuth: true,
            requiredAccess: {name: "ORGANIZATION", actions: ["UPDATE"]}
        }
    },
    {
        path: '/organization/password/reset',
        name: 'OrganizationPasswordReset',
        component: OrganizationPasswordReset
    },
    {
        path: '/applicant/password/reset',
        name: 'ApplicantPasswordReset',
        component: ApplicantPasswordReset
    },
    {
        path: '/organization/offers/add/:id?',
        params: ['id'],
        name: 'AddJobOfferAsLoggedUser',
        component: AddJobOfferAsLoggedUser,
        meta: {
            requiresOrganizationAuth: false,
        }
    },
    {
        path: '/organization/offers/:id/edit',
        params: 'id',
        name: 'PlannedJobOfferEdit',
        component: PlannedJobOfferEdit,
        meta: {
            requiresOrganizationAuth: true
        }
    },
    {
        path: '/applicant/profile',
        name: 'ApplicantProfilePage',
        component: ApplicantProfilePage,
        meta: {
            requiresEmployeeAuth: true
        }
    },
    {
        path: '/applicant/applications',
        name: 'ApplicantApplications',
        component: ApplicantApplications,
        meta: {
            requiresEmployeeAuth: true
        }
    },
    {
        path: '/applicant/observed',
        name: 'ObservedApplicationsPage',
        component: ObservedApplicationsPage,
        meta: {
            requiresEmployeeAuth: true
        }
    },
    {
        path: '/organization/profile',
        name: 'OrganizationAccountPage',
        component: OrganizationAccountPage,
        meta: {
            requiresOrganizationAuth: true,
        }
    },
    {
        path: '/organization/consents/accept/offers',
        name: 'EmailAcceptedForAddingExternalOffersPage',
        component: EmailAcceptedForAddingExternalOffersPage,
    },
    {
        path: '/organization/consents/decline/offers',
        name: 'EmailDeclineForAddingExternalOffersPage',
        component: EmailDeclineForAddingExternalOffersPage,
    },
    {
        path: '/organization/access-denied',
        name: 'OrganizationAccessDenied',
        component: OrganizationAccessDenied,
        meta: {}
    },
    {
        path: '/:pathMatch(.*)*',
        redirect: '/'
    }

]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})
router.beforeEach(async(to, from, next) => {
    await initCurrentUserOnReloadIfIsAuthenticated()
    if (currentUser.isAuthenticated()) {
        if (isMetaDeifned(to) && to.meta.requiresOrganizationAuth) {
            if (!currentUser.isOrganizationUser()) {
                sessionStorage.setItem('redirectPath', router.options.history.location);
                next({name: 'OrganizationLoginPage'});
            } else {
                if (currentUser.isAdmin() || hasAccessToPage(currentUser, to)) {
                    next();
                } else {
                    next("/organization/access-denied?rootPath=" + from.path)
                }
            }
        } else {
            next()
        }
    } else if (isMetaDeifned(to) && to.meta.requiresOrganizationAuth) {
        sessionStorage.setItem('redirectPath', router.options.history.location);
        next({name: 'OrganizationLoginPage'});
    } else {
        next()
    }
});

function hasAccessToPage(currentUser, to) {
    const requiredAccess = to.meta.requiredAccess;
    if (!requiredAccess) {
        return true;
    }
    return currentUser.permissions.some(permission => {
        if (permission.name !== requiredAccess.name) {
            return false;
        }
        if (requiredAccess.actions && requiredAccess.actions.length > 0) {
            return requiredAccess.actions.every(action => permission.actions.includes(action));
        }
        return true;
    });
}
 router.beforeEach(async (to, from, next) => {
    await initCurrentUserOnReloadIfIsAuthenticated();
    if (currentUser.isAuthenticated()) {
        if (isMetaDeifned(to) && to.meta.requiresEmployeeAuth) {
            if (!currentUser.isApplicant()) {
                sessionStorage.setItem('redirectPath', router.options.history.location);
                next({name: 'ApplicantLoginPage'});
            } else {
                next();
            }
        } else {
            next()
        }
    } else if (isMetaDeifned(to) && to.meta.requiresEmployeeAuth) {
        sessionStorage.setItem('redirectPath', router.options.history.location);
        next({name: 'ApplicantLoginPage'});
    } else {
        next()
    }
});

function isMetaDeifned(to) {
    return to.meta !== null && to.meta !== undefined
}

function initCurrentUserOnReloadIfIsAuthenticated() {
    return new Promise((resolve, reject) => {
        if (localStorage.getItem("isAuthenticated") === "true" && !currentUser.isAuthenticated()) {
            AuthenticationService.getAuthenticatedUser().then(response => {
                response.json().then(body => {
                    Object.assign(currentUser, body);
                    resolve();
                }).catch(err => reject(err));
            }).catch(err => reject(err));
        } else {
            resolve();
        }
    });
}

export default router
