<template>
  <q-layout class="col row">
    <q-page-container class="column col row bg-secondary">
      <q-page class="column col row">
        <mobile-menu v-if="isMobile()"></mobile-menu>
        <menu-bar v-else
                  :is-menu-visible="true"
                  @currentUser="val => setCurrentUser(val)">
        </menu-bar>
        <div ref="thisDivHeight" class="col row q-px-xs justify-center">
          <div class="column">
            <q-card bordered style="margin-top: 130px" class="q-pa-lg q-ma-md">
              <div class="row justify-center"></div>
              <q-card-section class="q-pa-lg">
                <div class="text-center">
                  <p class="text-h5 text-black q-mt-md" style="font-size: 30px !important;">
                    Przykro nam, że rezygnujesz ze współpracy.
                  </p>
                  <p class="text-h5 text-black q-mt-md" style="font-size: 23px !important;">
                    Oferty firmy <span class="text-bold accent-3">{{ companyName }}</span> zostaną wkrótce usunięte.
                  </p>
                  <p class="text-h5 text-black q-mt-lg q-pt-lg q-mb-sm" style="font-size: 15px !important;">
                    Po ich usunięciu prześlemy Państwu link z potwierdzeniem na adres e-mail&nbsp;
                    <span class="accent-3"> {{ companyEmail }}</span>.
                  </p>
                  <p class="text-h7 text-dark q-mb-sm" style="font-size: 15px!important;">
                    Jeśli przypadkowo anulowano zgodę lub chcesz ją przywrócić, kliknij w poniższy przycisk.
                  </p>
                </div>
                <div class="text-center q-mt-lg q-pt-lg q-gutter-md">
                  <div>
                    <q-btn
                        outline
                        class="bg-white text-dark q-px-lg"
                        dense
                        no-caps
                        size="lg"
                        @click="goToMainBoard()">
                      Potwierdzam rezygnację
                    </q-btn>
                  </div>
                 <div>
                   <q-btn
                       outline
                       class="bg-accent text-dark q-px-lg"
                       dense
                       no-caps
                       size="lg"
                       @click="sendAcceptEmailConsentsAgain()">
                     Wyrażam zgodę na dodanie ofert
                   </q-btn>
                 </div>
                </div>
              </q-card-section>
            </q-card>
          </div>
        </div>
      </q-page>
    </q-page-container>
  </q-layout>
</template>

<script setup>
import MenuBar from "/src/components/common/MenuBar.vue";
import {onMounted, ref} from "vue";
import {useQuasar} from "quasar";
import MobileMenu from "@/components/mobile/MobileMenu";
import {currentUser} from "@/global-variables/currentUser";
import {useRouter, useRoute} from "vue-router";

import {
  initializeFromQuery, sendAgreementConsentInformation,
  sendDeclineConsentInformation
} from "@/services/organization/offer/consents/OfferConsentsService";
import NotificationService from "@/services/notification/NotificationService";

const $q = useQuasar();
const router = useRouter();
const route = useRoute();

const thisDivHeight = ref(null);
const divHeight = ref(220);

const companyName = ref(null);
const companyEmail = ref(null);
const token = ref(null);

onMounted(async () => {
  const queryData = initializeFromQuery(route);
  companyName.value = queryData.companyName;
  companyEmail.value = queryData.companyEmail;
  token.value = queryData.token;

  calculateDivHeight();
  await sendWebhookToSendEmail();
});

async function goToMainBoard() {
  await router.push({name: "JobOffersMainBoard"});
}

function calculateDivHeight() {
  if (thisDivHeight.value) {
    divHeight.value = thisDivHeight.value.offsetHeight;
  }
}

async function sendWebhookToSendEmail() {
  try {
    await sendDeclineConsentInformation(companyEmail.value, token.value, companyName.value)
        .then(isSent => {
          if (!isSent) {
            NotificationService.showNotificationWithPosition($q, 'info', "Niepoprawny link do anulowania zgody - skontaktuj się z nami za pomocą maila, aby anulować zgodę.", 'bottom')
          }
        });
  } catch (error) {
    console.error("Error sending webhook:", error);
  }
}


async function sendAcceptEmailConsentsAgain() {
  try {
    await sendAgreementConsentInformation(companyEmail.value, token.value, companyName.value)
        .then(isSent => {
          if (isSent) {
            goToMainBoard();
          } else {
            NotificationService.showNotificationWithPosition($q, 'info', "Zgoda nie może być aktywowana, skontaktuj się z nami aby otrzymać nowy link aktywacyjny", 'bottom')
          }
        });
  } catch (error) {
    console.error("Error sending webhook:", error);
  }
}

function setCurrentUser(val) {
  currentUser.value = val;
}

function isMobile() {
  return $q.platform.is.mobile;
}
</script>

<style lang="scss" scoped>
.slide-wrap {
  width: 100vw;
  overflow-x: auto;
  overflow-y: hidden;
}

.slide {
  min-width: max-content;
}
</style>
