import {zapierHookApiInstance} from "@/services/zapier/ZapierHookApi";


export function initializeFromQuery(route) {
    return {
        companyName: route.query.company || null,
        companyEmail: route.query.email || null,
        token: route.query.securityToken || null,
    };
}


export async function sendAgreementConsentInformation(companyEmail, token, companyName) {
    if (companyEmail && token && companyName) {
        let response = await zapierHookApiInstance.sendConsentsInformation(companyEmail, token, true, companyName);
        if (response.status === 200) {
            return true
        } else {
            return false;
        }
    } else {
        return false
    }
}

export async function sendDeclineConsentInformation(companyEmail, token, companyName) {
    if (companyEmail && token && companyName) {
        let response = await zapierHookApiInstance.sendConsentsInformation(companyEmail, token, false, companyName);
        if (response.status === 200) {
            return true
        } else {
            return false;
        }
    } else {
        return false
    }
}
